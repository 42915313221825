/* Copy of /node_modules/inter-ui/inter-variable.css */
/* Renamed font to Inter instead of InterVariable */
/* Similar to DS font connection https://github.com/Bynder/design-system/blob/ac9d21f8a309448074f0e1a0128551cd1c9bf1c8/.storybook/inter.css  */

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url('inter-ui/variable/InterVariable.woff2') format('woff2');
}

@font-face {
	font-family: Inter;
	font-style: italic;
	font-weight: 100 900;
	font-display: swap;
	src: url('inter-ui/variable/InterVariable-Italic.woff2') format('woff2');
}