@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6f7177;
  --gray-dark: #343a40;
  --primary: #1b5e9e;
  --secondary: rgba(0, 34, 51, 0.1019607843);
  --success: #00aaff;
  --info: #9a6ce1;
  --warning: #f0a11e;
  --danger: #e34557;
  --light: rgba(0, 34, 51, 0.031372549);
  --dark: #002233;
  --gray: #4a4a4a;
  --body: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: Inter, "Source Sans 3", "Source Sans Pro", SourceSansPro, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #334e5c;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.625rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #334e5c;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #18242b;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #809199;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}
progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.625rem;
  font-weight: 600;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.34375rem + 1.125vw);
  }
}
h2,
.h2 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.3rem + 0.6vw);
  }
}
h3,
.h3 {
  font-size: 1.53125rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.278125rem + 0.3375vw);
  }
}
h4,
.h4 {
  font-size: 1.3125rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}
h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 1px solid rgba(0, 34, 51, 0.1019607843);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6f7177;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6f7177;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1.25rem;
  color: #334e5c;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #f8f9f9;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #f8f9f9;
}

.table tbody + tbody {
  border-top: 0 solid #f8f9f9;
}

.table-body,
.table-body > th,
.table-body > td {
  background-color: white;
}

.table-body th,
.table-body td,
.table-body thead th,
.table-body tbody + tbody {
  border-color: white;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #334e5c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 34, 51, 0.1019607843);
  border-radius: 5px;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #334e5c;
  background-color: #fff;
  border-color: none;
  outline: 0;
}

.form-control::-moz-placeholder {
  color: #809199;
  opacity: 1;
}

.form-control::placeholder {
  color: #809199;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #334e5c;
}

select.form-control:focus::-ms-value {
  color: #334e5c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #334e5c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #00aaff;
  padding-right: calc(1.5em + 1.25rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300aaff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #00aaff;
  box-shadow: 0 0 0 0 rgba(0, 170, 255, 0.25);
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 5rem !important;
  background-position: right 2.5rem center;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #00aaff;
  padding-right: calc(0.75em + 3.1875rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1.25rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300aaff' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2.25rem/calc(0.75em + 0.625rem) calc(0.75em + 0.625rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #00aaff;
  box-shadow: 0 0 0 0 rgba(0, 170, 255, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #00aaff;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #00aaff;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00aaff;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #33bbff;
  background-color: #33bbff;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 170, 255, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00aaff;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00aaff;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00aaff;
  box-shadow: 0 0 0 0 rgba(0, 170, 255, 0.25);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e34557;
  padding-right: calc(1.5em + 1.25rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e34557' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e34557' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e34557;
  box-shadow: 0 0 0 0 rgba(227, 69, 87, 0.25);
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 5rem !important;
  background-position: right 2.5rem center;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e34557;
  padding-right: calc(0.75em + 3.1875rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1.25rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e34557' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e34557' stroke='none'/%3e%3c/svg%3e") center right 2.25rem/calc(0.75em + 0.625rem) calc(0.75em + 0.625rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e34557;
  box-shadow: 0 0 0 0 rgba(227, 69, 87, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e34557;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e34557;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e34557;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea717f;
  background-color: #ea717f;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(227, 69, 87, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e34557;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e34557;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e34557;
  box-shadow: 0 0 0 0 rgba(227, 69, 87, 0.25);
}

.btn,
.navigation-item {
  display: inline-block;
  font-weight: 400;
  color: #334e5c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .navigation-item {
    transition: none;
  }
}
.btn:hover,
.navigation-item:hover {
  color: #334e5c;
  text-decoration: none;
}

.btn:focus,
.navigation-item:focus,
.btn.focus,
.focus.navigation-item {
  outline: 0;
  box-shadow: none;
}

.btn.disabled,
.disabled.navigation-item,
.btn:disabled,
.navigation-item:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled),
.navigation-item:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.navigation-item:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.navigation-item:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

a.btn.disabled,
a.disabled.navigation-item,
fieldset:disabled a.btn,
fieldset:disabled a.navigation-item {
  pointer-events: none;
}

.btn-secondary {
  color: #fff;
  background-color: rgba(0, 34, 51, 0.1019607843);
  border-color: rgba(0, 34, 51, 0.1019607843);
  box-shadow: none;
}

.btn-secondary:hover {
  color: #fff;
  background-color: rgba(0, 9, 13, 0.1019607843);
  border-color: rgba(0, 0, 0, 0.1019607843);
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: rgba(0, 9, 13, 0.1019607843);
  border-color: rgba(0, 0, 0, 0.1019607843);
  box-shadow: 0 0 0 0 rgba(195, 203, 207, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: rgba(0, 34, 51, 0.1019607843);
  border-color: rgba(0, 34, 51, 0.1019607843);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1019607843);
  border-color: rgba(0, 0, 0, 0.1019607843);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(195, 203, 207, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00aaff;
  border-color: #00aaff;
  box-shadow: none;
}

.btn-success:hover {
  color: #fff;
  background-color: #0091d9;
  border-color: #0088cc;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #0091d9;
  border-color: #0088cc;
  box-shadow: 0 0 0 0 rgba(38, 183, 255, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0088cc;
  border-color: #0080bf;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 183, 255, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #334e5c;
  text-decoration: none;
}

.btn-link:hover {
  color: #18242b;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6f7177;
  pointer-events: none;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .navigation-item {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 10px 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #334e5c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  box-shadow: 0 4px 6px -2px rgba(0, 34, 51, 0.1), 0 0 0 1px rgba(0, 34, 51, 0.1);
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #809199;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #334e5c;
  text-decoration: none;
  background-color: rgba(0, 34, 51, 0.031372549);
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00aaff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #809199;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 10px 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6f7177;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 10px 1.5rem;
  color: #809199;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .navigation-item,
.input-group-append .btn,
.input-group-append .navigation-item {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .navigation-item:focus,
.input-group-append .btn:focus,
.input-group-append .navigation-item:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .navigation-item + .btn,
.input-group-prepend .btn + .navigation-item,
.input-group-prepend .navigation-item + .navigation-item,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .navigation-item + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .navigation-item,
.input-group-append .btn + .btn,
.input-group-append .navigation-item + .btn,
.input-group-append .btn + .navigation-item,
.input-group-append .navigation-item + .navigation-item,
.input-group-append .btn + .input-group-text,
.input-group-append .navigation-item + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .navigation-item {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #809199;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid rgba(0, 34, 51, 0.1019607843);
  border-radius: 5px;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .navigation-item,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .navigation-item {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .navigation-item,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .navigation-item {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.25rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .navigation-item,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .navigation-item,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .navigation-item,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .navigation-item:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .navigation-item,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .navigation-item,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .navigation-item:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 2.5625rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 2.25rem 0.625rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #334e5c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1.25rem center/8px 10px no-repeat;
  border: 1px solid rgba(0, 34, 51, 0.1019607843);
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(27, 94, 158, 0.25);
}

.custom-select:focus::-ms-value {
  color: #334e5c;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.25rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6f7177;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #334e5c;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav-link {
  display: block;
  padding: 1.875rem 1.875rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6f7177;
  pointer-events: none;
  cursor: default;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 5px;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #fff !important;
  text-decoration: none;
  background-color: #082839 !important;
}

.list-group-item-action:active {
  color: #fff;
  background-color: #0d2d3d;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6f7177;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff !important;
  background-color: #0d2d3d;
  border-color: #0d2d3d;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #f8f9f9;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #002233;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 0 solid rgba(0, 34, 51, 0.1019607843);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 30px;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.875rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.625rem;
  border-top: 0 solid rgba(0, 34, 51, 0.1019607843);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #e34557;
  background-clip: padding-box;
  border: 1px solid #e34557;
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.popover-body {
  padding: 20px 20px;
  color: white;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-success {
  background-color: #00aaff !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0088cc !important;
}

.border {
  border: 1px solid rgba(0, 34, 51, 0.1019607843) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 34, 51, 0.1019607843) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 34, 51, 0.1019607843) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 34, 51, 0.1019607843) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 34, 51, 0.1019607843) !important;
}

.border-0 {
  border: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-success {
  border-color: #00aaff !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom,
.container-auth .card-body {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.3125rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.625rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important;
}

.m-3 {
  margin: 1.25rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.25rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.25rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.25rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.875rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.875rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.875rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.875rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important;
}

.m-6 {
  margin: 7.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 7.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 7.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 7.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 7.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.625rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.625rem !important;
}

.p-3 {
  padding: 1.25rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.25rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.25rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.25rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.875rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.875rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.875rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.875rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.75rem !important;
}

.p-6 {
  padding: 7.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 7.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 7.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 7.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 7.5rem !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.3125rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.3125rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.3125rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.625rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.625rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.625rem !important;
}

.m-n3 {
  margin: -1.25rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.25rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.25rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.25rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.25rem !important;
}

.m-n4 {
  margin: -1.875rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.875rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.875rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.875rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.875rem !important;
}

.m-n5 {
  margin: -3.75rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3.75rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3.75rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3.75rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3.75rem !important;
}

.m-n6 {
  margin: -7.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -7.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -7.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -7.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -7.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-dark {
  color: #002233 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: black !important;
}

.text-muted {
  color: #809199 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.25625rem + 0.075vw);
  }
}
.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 8px 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #002233;
  border-radius: 5px;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 5px;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1b5e9e;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.custom-control-input {
  width: 16px;
  height: 16px;
  top: 0.15625rem;
}

.custom-control-label::before {
  border: none;
}

a.bg-success,
button.bg-success,
.bg-success {
  background-color: #00aaff !important;
}

a.bg-success:hover,
button.bg-success:hover,
.bg-success:hover {
  background-color: #00aaff !important;
}

.form-control-sm {
  height: auto;
}

svg {
  vertical-align: baseline;
}

.input-group-text:not(.input--color-picker) {
  border-left: 0;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.input-group:not(.input-group-sm) .input-group-prepend {
  height: 43px;
  line-height: 43px;
}

.input-group.icon-inside {
  padding-left: 12px;
  padding-right: 15px;
  border: 1px solid var(--gray100);
  border-radius: 5px;
}

.input-group.icon-inside input {
  padding-left: 11px;
  font-size: 16px;
}

.input-group.icon-inside .input-group-prepend span {
  padding-right: 0;
  font-size: 16px;
}

.input-group.icon-inside .form-control,
.input-group.icon-inside .input-group-prepend span {
  border: none;
}

body {
  min-width: 1200px;
}

div.disable {
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: 20;
}

pre {
  white-space: normal;
}

a.link {
  color: #00aaff;
}

.custom-control-label {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.custom-control-label::before,
.custom-control-label::after {
  left: 0;
}

.shadow-0 {
  box-shadow: none !important;
}

.shadow {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
}

.shadow-bottom {
  z-index: 10;
  box-shadow: 6px 14px 44px -25px rgba(0, 0, 0, 0.75);
}

.shadow-bottom-light {
  z-index: 11;
  box-shadow: 6px 14px 44px -25px rgba(0, 0, 0, 0.3);
}

.shadow-success {
  box-shadow: 0 5px 8.5px 0 rgba(77, 194, 108, 0.4);
}

.cursor-move {
  cursor: move;
}

.cursor-pointer,
button:not([disabled]):not(.disabled) {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.border-md {
  border-width: 3px !important;
}

.text-inherit,
text-inherit:hover {
  color: inherit !important;
}

:hover > svg.text-hover-success {
  color: #00aaff !important;
}

.text-hover-show {
  display: none;
}

:hover > .text-hover-show {
  display: inline-block;
}

.table {
  border-spacing: 0 2px;
  border-collapse: separate;
}

.table-hover tbody tr:hover,
tr.shadow,
.shadow-hover:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.mh-250p {
  max-height: 250px;
}

.mh-300p {
  max-height: 300px;
}

.mh-350p {
  max-height: 350px;
}

.mh-400p {
  max-height: 400px;
}

.mh-450p {
  max-height: 450px;
}

.mh-500p {
  max-height: 500px;
}

.minh-100 {
  min-height: 100%;
}

.mh-0 {
  max-height: 0;
}

.mw-0 {
  max-width: 0;
}

.minw-60p {
  min-width: 60px;
}

.h-10 {
  height: 10%;
}

.h-90 {
  height: 90%;
}

.h-80 {
  height: 80%;
}

.mh-80 {
  max-height: 80%;
}

.h-20 {
  height: 20%;
}

.mh-20 {
  max-height: 20%;
}

.w-600p {
  width: 600px;
}

.h-24 {
  height: 24px;
}

.h-initial {
  height: initial;
}

.d-contents {
  display: contents;
}

.flex-1 {
  flex: 1;
}

.transition {
  transition: all 300ms ease-in;
}

.transition-transform {
  transition: transform 300ms ease-in;
}

.transition-opacity {
  transition: opacity 300ms ease-in;
}

.transform-180 {
  transition: transform 300ms ease-in;
  transform: rotate(180deg);
}

.transform-90 {
  transition: transform 300ms ease-in;
  transform: rotate(90deg);
}

.transform--90 {
  transition: transform 300ms ease-in;
  transform: rotate(-90deg);
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-title {
  font-size: 1.3rem;
  font-weight: 400;
}

.bg-white {
  background: #fff;
}

.shadow-top {
  box-shadow: 2px -1px 6px -2px rgba(0, 34, 51, 0.1);
  border-top: 1px solid rgba(0, 34, 51, 0.15);
}

.not-resizable {
  resize: none;
}

.full-width-button {
  width: 100%;
}

.custom-checkbox {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.text-decoration-white {
  text-decoration-color: #fff !important;
}

.d-grid {
  display: grid;
}

.btn-disabled {
  opacity: 0.65;
  box-shadow: none;
  pointer-events: none;
}

.opacity-1 {
  opacity: 1 !important;
}

.align-items-center {
  align-items: center;
}

.will-change-unset {
  will-change: unset !important;
}

.disabled-item {
  pointer-events: none;
  opacity: 0.4;
}

.transform-none {
  transform: none !important;
}

.word-break-all {
  white-space: normal;
  word-break: break-all;
}

.hover-success:hover {
  background-color: #00aaff;
}

.hover-dark:hover {
  background-color: #002233 !important;
  color: white;
}

.bg-none {
  background-color: transparent;
}

.font-family-sourceSansPro {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif !important;
}

.z-md {
  z-index: 2;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 3px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

.dropdown-menu {
  padding: 10px 10px;
  min-width: 0px;
}

.dropdown-item:focus:not(hover) {
  outline: none;
  color: #002233;
  background: #fff;
}

.dropdown-item.active:focus:not(hover) {
  outline: none;
  color: #fff;
  background: #00aaff;
}

/**
Media Queries
 */
@media only screen and (max-width: 1800px) and (max-height: 2880px) {
  .modal-footer {
    padding: 30px;
  }
}
@media only screen and (max-height: 1600px) and (max-width: 2560px) {
  .modal-dialog.modal-lg .modal-footer {
    padding: 20px;
  }
}
@media only screen and (max-height: 900px) and (max-width: 1440px) {
  .modal-footer {
    padding: 15px;
  }
}
@media only screen and (max-width: 800px) and (max-height: 1280px) {
  .modal-dialog.modal-lg .modal-footer {
    padding: 15px;
  }
}
.text-editor-dropdown {
  position: absolute;
  z-index: 1033;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: 224px;
  max-width: 336px;
  max-height: 350px;
  padding: 8px 0;
  border-radius: 5px;
  background: #ffffff;
  color: #354051;
  box-shadow: 0 0 0 1px rgba(3, 16, 38, 0.1), 0 2px 4px -1px rgba(3, 16, 38, 0.1);
}

.text-editor-dropdown button {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 16px;
  border: none;
  background: none;
  color: #68707d;
  outline: none;
  font-family: inherit;
  cursor: pointer;
}

.text-editor-dropdown button:hover {
  background: #f8f8f9;
  color: #354051;
}

.btn-secondary {
  color: #111;
  background-color: #fff;
  border-color: rgba(0, 34, 51, 0.1019607843);
  box-shadow: none;
  color: #334e5c !important;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #f3f4f5;
  border-color: rgba(0, 34, 51, 0.1019607843);
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #f3f4f5;
  border-color: rgba(0, 34, 51, 0.1019607843);
  box-shadow: 0 0 0 0 rgba(13, 21, 25, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #111;
  background-color: #fff;
  border-color: rgba(0, 34, 51, 0.1019607843);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #d9dee1;
  border-color: rgba(0, 34, 51, 0.1019607843);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(13, 21, 25, 0.5);
}

.container-auth {
  margin: 0 auto;
  box-shadow: 0 4px 6px -2px rgba(0, 34, 51, 0.1), 0 0 0 1px rgba(0, 34, 51, 0.1);
  border-radius: 5px;
  width: 600px;
}

.container-auth .card-body {
  background: #fff;
}

.form-control-plaintext:focus,
.list-group-item:focus {
  outline: none;
}

.input-group .form-control {
  z-index: initial;
}

.tooltip-inner {
  max-width: initial;
  text-align: initial;
}

.form-group > label > button {
  line-height: 1.2;
}

.modal-dialog.modal-lg {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 780px;
  text-align: left;
  vertical-align: middle;
}

.modal-dialog.modal-lg .justify-content-start {
  position: relative;
}

.modal-dialog.modal-lg .justify-content-end {
  top: -2px;
}

.modal-dialog.modal-lg .modal-body .text-dark {
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-dialog.modal-lg .trim--modal-view {
  height: calc(100% - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-dialog.modal-lg .modal-content {
  width: 100%;
  height: 100%;
}

.modal-dialog.modal-lg .modal-content .modal-body {
  height: calc(100% - 72px);
}

.mb-20 {
  margin-block-end: 20px;
}

#scroll-wrapper {
  height: 100%;
  overflow: hidden;
}

.trim-modal--tooltip {
  pointer-events: none;
  z-index: 1054;
}

.modal.player--modal {
  max-width: 1300px;
  max-height: 780px;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal--player-wrapper.full-screen {
  width: calc(100% - 100px);
  margin: 0 auto;
}

.modal-backdrop {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.full-screen .video-js {
  width: auto !important;
  height: 80vh !important;
}

.layers--parent {
  height: calc(100% - 160px);
}

.editor-container {
  padding-right: 20px;
}

.zoom-control-wrapper {
  margin-bottom: 1px;
}

.canvas-container-wrapper {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-grow: 1;
  overflow: hidden;
}

.canvas-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
}

.canvas-container canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.editor-canvas-external-video {
  padding-left: 0;
  padding-right: 0;
  margin-left: -16px;
  margin-right: -20px;
  width: calc(100% + 36px);
  max-width: none;
}

.canvas-container-wrapper-image {
  max-width: calc(100% - 272px + 20px);
  margin-right: -20px;
}
